import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Container from '../Container'
import AvailablePlans, { CustomPricing } from '../Plans'
import { StaticImage } from 'gatsby-plugin-image'

interface PlansProps {
  customPricingOptions?: CustomPricing[]
  whatsAppCustomMessage?: string
  whatsAppCustomMessageSlug?: string
}

export const Plans = ({ customPricingOptions, whatsAppCustomMessage, whatsAppCustomMessageSlug }: PlansProps) => {
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        ...PlansInfo
      }
    }
  `)

  const { linkWhatsapp, pricing } = strapiGlobal as Queries.PlansInfoFragment

  return (
    <div id='planos' style={{ display: 'grid' }}>
      <StaticImage
        className='row-start-1 col-start-1 lg:max-h-[1450px] xl:max-h-[1120px]'
        layout='fullWidth'
        alt=''
        src='../../images/grey-bg.png'
      />
      <div style={{ gridArea: '1/1', position: 'relative', placeItems: 'center', display: 'grid' }}>
        <Container className='flex flex-col gap-8 py-12 xl:py-20 text-white'>
          <div className='flex flex-col gap-4 text-center xl:text-left xl:w-2/3'>
            <h3 className='text-primary-600 font-semibold text-2xl'>Nossos planos</h3>
            <p>
              Oferecemos serviços para todas as regiões do Brasil. Escolha a opção que mais se adequa às suas necessidades ou solicite um
              plano personalizado.
            </p>
          </div>

          <AvailablePlans
            pricing={pricing}
            customPricingOptions={customPricingOptions}
            contactUrl={linkWhatsapp || ''}
            secondaryColor='#000000'
            planCardOptions={{
              blurred: true,
            }}
            whatsAppCustomMessage={whatsAppCustomMessage}
            whatsAppCustomMessageSlug={whatsAppCustomMessageSlug}
          />
        </Container>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment GlobalPlansContent on STRAPI__COMPONENT_PRICING_PLAN_PRICE {
    id
    valueFrom
    value
    monthly
    # highlightChipText
  }

  fragment GlobalPricingInfo on STRAPI_GLOBAL {
    pricing {
      id
      meiCommerce {
        ...GlobalPlansContent
      }
      meiService {
        ...GlobalPlansContent
      }
      simplesNacionalCommerce {
        ...GlobalPlansContent
      }
      simplesNacionalService {
        ...GlobalPlansContent
      }
      otherCommerce {
        ...GlobalPlansContent
      }
      otherService {
        ...GlobalPlansContent
      }
      digitalCertificate {
        ...GlobalPlansContent
      }
    }
  }

  fragment PlansInfo on STRAPI_GLOBAL {
    id
    linkWhatsapp
    ...GlobalPricingInfo
  }
`

export default Plans
