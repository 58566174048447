import React from 'react'
import { Link, Script, graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { FaInstagramSquare, FaLinkedin, FaFacebookSquare, FaYoutube } from 'react-icons/fa'

import { AppStoreBadges, Button, Container } from './'
import { useAnalytics } from '../hooks'
import { formatWhatsAppLink } from '../services/formatter'

interface Props {
  whatsAppCustomMessage?: string
}

export const Footer = ({ whatsAppCustomMessage }: Props) => {
  const { fireEvent } = useAnalytics()
  const { strapiGlobal, segmentLandingPages, productLandingPages } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        ...FooterInfo
      }
      segmentLandingPages: allStrapiSegmentLandingPage {
        nodes {
          ...SegmentLandingPageInfo
        }
      }
      productLandingPages: allStrapiProductLandingPage {
        nodes {
          ...ProductLandingPageInfo
        }
      }
    }
  `)

  const footerInfo = strapiGlobal as Queries.FooterInfoFragment
  const addressInfo = footerInfo.address as Queries.AddressInfoFragment
  const segmentLandingPagesInfo = segmentLandingPages.nodes as Queries.SegmentLandingPageInfoFragment[]
  const productLandingPagesInfo = productLandingPages.nodes as Queries.ProductLandingPageInfoFragment[]

  const socialMedias = [
    {
      icon: <FaInstagramSquare aria-label='Ícone do Instagram' size={28} />,
      link: footerInfo.linkInstagram,
      analyticsTarget: 'linkInstagram',
    },
    { icon: <FaLinkedin aria-label='Ícone do Linkedin' size={28} />, link: footerInfo.linkLinkedin, analyticsTarget: 'linkLinkedin' },
    { icon: <FaFacebookSquare aria-label='Ícone do Facebook' size={28} />, link: footerInfo.linkFacebook, analyticsTarget: 'linkFacebook' },
    {
      icon: <FaYoutube aria-label='Ícone do Youtube' size={28} />,
      link: footerInfo.linkYoutubeChannel,
      analyticsTarget: 'linkYoutubeChannel',
    },
  ]

  // Disparo do evento de clique externo
  const fireAnalyticsEvent = (target: string) => {
    fireEvent('adsConversion', { component: 'FooterLink', target })

    fireEvent('externalClick', {
      component: 'Footer',
      target,
    })
  }

  return (
    <footer>
      <div className='w-full rounded-t-3xl py-12 px-5 sm:px-8 bg-gradient-to-r from-gradient-blue to-gradient-fuchsia text-white'>
        <Container className='flex flex-col lg:flex-row sm:justify-between gap-8'>
          <div className='flex flex-col items-center gap-6'>
            <StaticImage
              width={120}
              src='../images/logos/logo-white.png'
              alt='Logo Hubs Contabilidade'
              className='max-w-[120px]'
              placeholder='blurred'
              quality={100}
            />

            <div className='text-center leading-normal font-extralight'>
              <p className='text-md'>{`${addressInfo.street}, ${addressInfo.number}`}</p>
              <p className='text-md'>{`${addressInfo.complement}, ${addressInfo.neighborhood}`}</p>
              <p className='text-md'>{`${addressInfo.city}/${addressInfo.state} - ${addressInfo.postalCode}`}</p>
            </div>

            <div className='text-center leading-normal font-extralight'>
              <p className='text-md'>
                <span className='font-medium text-md'>CNPJ:</span> {footerInfo.cnpj}
              </p>
              <p className='text-md'>
                <span className='font-medium text-md'>CRC Hubs:</span> {footerInfo.crc}
              </p>
              <p className='text-md'>
                <span className='font-medium text-md'>Responsável Técnico:</span> {footerInfo.technicalResponsible}
              </p>
            </div>

            <div className='flex flex-col gap-2 text-center'>
              <p className='font-semibold text-primary-600'>Horário de atendimento:</p>
              <div className='lowercase leading-normal font-extralight'>
                <p className='text-md'>{footerInfo.serviceWorkingDays}</p>
                <p className='text-md'>{footerInfo.serviceWorkingHours}</p>
              </div>

              <p className='text-md font-extralight'>
                <span className='font-medium'>Telefone:</span> {footerInfo.contactPhone}
              </p>
            </div>
          </div>

          {Boolean(segmentLandingPagesInfo.length) && (
            <div className='flex flex-col gap-2 text-center xl:text-left'>
              <p className='font-semibold text-primary-600'>Contabilidade para especialistas:</p>
              <div className='text-xs leading-5 font-extralight flex flex-col gap-2'>
                {segmentLandingPagesInfo.map((item) => (
                  <Link key={item.id} to={`/segmentos/${item.slug}`}>
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          )}

          {Boolean(productLandingPagesInfo.length) && (
            <div className='flex flex-col gap-2 text-center xl:text-left'>
              <p className='font-semibold text-primary-600'>Ofertas de serviços:</p>
              <div className='text-xs leading-5 font-extralight flex flex-col gap-2'>
                {productLandingPagesInfo.map((item) => (
                  <Link key={item.id} to={`/ofertas/${item.slug}`}>
                    {item.heroSettings?.title}
                  </Link>
                ))}
              </div>
            </div>
          )}

          <div className='flex flex-col items-center gap-8'>
            <div className='flex flex-col gap-2'>
              <p className='font-semibold text-primary-600'>Nossas redes sociais:</p>

              <div className='flex flex-row justify-between'>
                {socialMedias.map((media, index) => (
                  <a
                    key={index}
                    href={media.link || ''}
                    rel='noreferrer noopener'
                    target='_BLANK'
                    onClick={() => fireAnalyticsEvent(media.analyticsTarget)}
                  >
                    <div key={index}>{media.icon}</div>
                  </a>
                ))}
              </div>
            </div>

            <a
              href={formatWhatsAppLink(footerInfo.linkWhatsapp || '', { customMessage: whatsAppCustomMessage })}
              rel='noreferrer noopener'
              target='_BLANK'
              onClick={() => fireAnalyticsEvent('linkWhatsapp')}
            >
              <Button>Falar com nossos especialistas</Button>
            </a>

            <div className='flex flex-col gap-2 items-center w-full'>
              <p className='font-semibold'>Baixe o aplicativo:</p>

              <AppStoreBadges className='flex justify-center gap-4 w-full' />
            </div>

            <div id='ra-verified-seal' className='ra-verified-seal'>
              <Script
                type='text/javascript'
                id='ra-embed-verified-seal'
                src='https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js'
                data-id='UUljMFdQUXFIUFRYMERYajpodWJzLWNvbnRhYmlsaWRhZGU='
                data-target='ra-verified-seal'
                data-model='2'
              />
            </div>
          </div>
        </Container>
      </div>
      <div className='flex flex-col md:flex-row gap-3 md:gap-1 py-4 justify-center items-center bg-secondary-900 text-white'>
        <Link className='text-[12px]' to='/privacidade'>
          Política de Privacidade
        </Link>
        <p className='hidden md:block text-[12px]'>·</p>
        <Link className='text-[12px]' to='/politica-cookies'>
          Política de Cookies
        </Link>
        <p className='hidden md:block text-[12px]'>·</p>
        <Link className='text-[12px]' to='/termos'>
          Termos de Uso
        </Link>
        <p className='hidden md:block text-[12px]'>·</p>
        <Link
          className='text-[12px]'
          to={footerInfo.linkDigitalCertificateBusinessPractices || ''}
          target='_BLANK'
          rel='noreferrer noopener'
        >
          Declaração de Práticas de Negócios da AR Hubs Soluções (CD)
        </Link>
        <p className='hidden md:block text-[12px]'>·</p>
        <Link
          className='text-[12px]'
          to={footerInfo.linkInternalRevenueServiceBusinessPractices || ''}
          target='_BLANK'
          rel='noreferrer noopener'
        >
          Declaração de Práticas de Negócios da AR Hubs Soluções (RFB)
        </Link>
      </div>
    </footer>
  )
}

export const query = graphql`
  fragment FooterInfo on STRAPI_GLOBAL {
    id
    cnpj
    contactPhone
    crc
    linkAppGlobal
    linkAppPlayStore
    linkAppStore
    linkFacebook
    linkGoogleMaps
    linkInstagram
    linkLinkedin
    linkWhatsapp
    linkYoutubeChannel
    linkDigitalCertificateBusinessPractices
    linkInternalRevenueServiceBusinessPractices
    serviceWorkingDays
    serviceWorkingHours
    technicalResponsible
    address {
      ...AddressInfo
    }
  }
  fragment SegmentLandingPageInfo on STRAPI_SEGMENT_LANDING_PAGE {
    id
    name
    slug
  }
  fragment ProductLandingPageInfo on STRAPI_PRODUCT_LANDING_PAGE {
    id
    slug
    heroSettings {
      title
    }
  }
`

export default Footer
