import React from 'react'
import { MdOutlineWhatsapp } from 'react-icons/md'
import { classNames } from '../services/string'
import { formatWhatsAppLink } from '../services/formatter'

interface WhatsAppButtonProps {
  className?: string
  href: string
  onClick?: () => unknown
  whatsAppCustomMessageSlug?: string
  whatsAppCustomMessage?: string
}

const WhatsAppButton = ({ className, href, onClick, whatsAppCustomMessage, whatsAppCustomMessageSlug }: WhatsAppButtonProps) => {
  return (
    <div className={className}>
      <a
        href={formatWhatsAppLink(href || '', { slug: whatsAppCustomMessageSlug, customMessage: whatsAppCustomMessage })}
        rel='noreferrer noopener'
        target='_BLANK'
        aria-label='Link do Whatsapp'
        className={classNames(
          'flex justify-center items-center float-right',
          'fixed bottom-4 right-4 lg:bottom-auto lg:top-0 lg:right-0 z-[10000]',
          'mt-2 px-3 h-14 lg:h-12 w-14 lg:w-12 rounded-full lg:rounded-l-lg lg:rounded-r-none',
          'bg-whatsapp lg:bg-secondary-800 font-xl drop-shadow-lg shadow-lg',
          'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-800',
        )}
        onClick={onClick}
      >
        <MdOutlineWhatsapp aria-label='Ícone do Whatsapp' size='32' className='text-white lg:text-primary-600' />
      </a>
    </div>
  )
}

export default WhatsAppButton
