import React from 'react'
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'
import type { Property } from 'csstype'

import AvailableFormsOfService from '../AvailableFormsOfService'
import FAQBox from './FAQBox'

interface FAQBoxProps {
  questions?: Queries.FAQInfoFragment[]
  extraQuestions?: Queries.FAQInfoFragment[]
  primaryColor?: Property.Color
  secondaryColor?: Property.Color
  image?: IGatsbyImageData
  whatsAppCustomMessage?: string
  whatsAppCustomMessageSlug?: string
}

const FAQ = (props: FAQBoxProps) => {
  const { primaryColor, image, whatsAppCustomMessage, whatsAppCustomMessageSlug, ...rest } = props

  return (
    <div className='flex flex-col lg:flex-row gap-16 lg:gap-8 xl:gap-16 py-12 xl:py-20'>
      <div className='flex flex-col gap-8 lg:w-1/2 xl:w-2/3'>
        <h3 className='font-semibold text-2xl text-center'>Dúvidas frequentes</h3>

        <FAQBox primaryColor={primaryColor} {...rest} />
      </div>

      <div className='flex flex-col items-center gap-8 lg:w-1/2 xl:w-1/3'>
        <div className='hidden lg:flex justify-center items-center w-full'>
          {image && <GatsbyImage image={image} className='w-[300px]' alt='Imagem de uma pessoa com expressão de dúvida' />}
          {!image && (
            <StaticImage
              width={300}
              src='../../images/resources/man-in-doubt.png'
              alt='Imagem de uma pessoa com expressão de dúvida'
              className='w-[300px]'
              placeholder='blurred'
              quality={100}
            />
          )}
        </div>

        <div className='flex flex-col gap-2 text-center'>
          <h3 className='font-semibold text-2xl'>Ainda está com dúvidas?</h3>
          <p>Fale agora com nossos especialistas!</p>
        </div>
        <AvailableFormsOfService
          primaryColor={primaryColor}
          whatsAppCustomMessage={whatsAppCustomMessage}
          whatsAppCustomMessageSlug={whatsAppCustomMessageSlug}
        />
      </div>
    </div>
  )
}

export default FAQ
