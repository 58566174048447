import React, { Suspense, lazy } from 'react'
import { Loading, NavBar, RootBackgroundImage } from '../'
const Footer = lazy(() => import('../Footer'))

export interface LayoutProps {
  children: React.ReactNode
  extraParams?: {
    whatsAppCustomMessage?: string
  }
}

export const Layout = ({ extraParams, children }: LayoutProps) => {
  // const hasMounted = useHasMounted()

  return (
    <RootBackgroundImage>
      <NavBar whatsAppCustomMessage={extraParams?.whatsAppCustomMessage} />
      <div className='mt-16'>{children}</div>
      <Suspense fallback={<Loading />}>
        <Footer whatsAppCustomMessage={extraParams?.whatsAppCustomMessage} />
      </Suspense>
    </RootBackgroundImage>
  )
}

export default Layout
