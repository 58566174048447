import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import Container from '../Container'
import VanillaFAQ from '../FAQ'

export interface FAQProps {
  image?: IGatsbyImageData
  extraQuestions?: Queries.FAQInfoFragment[]
  whatsAppCustomMessage?: string
  whatsAppCustomMessageSlug?: string
  ignoreGlobalFaq?: boolean
}

const FAQ = ({ image, extraQuestions, whatsAppCustomMessage, whatsAppCustomMessageSlug, ignoreGlobalFaq }: FAQProps) => {
  const queryResult = !ignoreGlobalFaq
    ? useStaticQuery<Queries.FaqGlobalQuery>(graphql`
        query FaqGlobal {
          strapiGlobal {
            faq {
              ...FAQInfo
            }
          }
        }
      `)
    : undefined

  const strapiGlobal = queryResult?.strapiGlobal || undefined

  return (
    <Container>
      <VanillaFAQ
        image={image}
        questions={strapiGlobal ? (strapiGlobal.faq as Queries.FAQInfoFragment[]) : undefined}
        extraQuestions={extraQuestions}
        whatsAppCustomMessage={whatsAppCustomMessage}
        whatsAppCustomMessageSlug={whatsAppCustomMessageSlug}
      />
    </Container>
  )
}

export const query = graphql`
  fragment FAQInfo on STRAPI__COMPONENT_SHARED_FAQ {
    id
    question
    answer {
      data {
        childMarkdownRemark {
          id
          html
        }
      }
    }
  }
`

export default FAQ
